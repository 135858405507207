.search-coin-input {
    outline: none;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 50%;
    padding: 5px;
    color: white;
}

.search-coin-element {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #6f6f6f;
    padding: 10px;
}
.search-coin-element:hover {
    cursor: pointer;
}

.search-coin-element-logo {
    width: 17px;
    height: 17px;
    margin-right: 6px;
    /*background-color: white;*/
    border-radius: 10px;
    border: 1px solid;
}

.search-coin-element-logo-img {
    width: 20px;
    height: 20px;
    border-radius: 10px;
}
