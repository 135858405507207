.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: none;
    max-height: 85%;
    overflow: auto;
}

.modal-content {
    margin-top: 5%;
    border-radius: 12px;
    max-width: 600px;
    width: 95%;
    background-color: #444444e6;
    backdrop-filter: blur(8px);
}

.modal.active {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.modal-close {
    height: 44px;
    width: 44px;
    border-radius: 50%;
    background-color: #ffffff1f;
    border: 0;
    padding: 0;
}
.modal-close:hover {
    cursor: pointer;
}
