.activities-item {
    padding: 2px 8px 8px;
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: var(--background-card);
}
.activities-item:hover {
    cursor: pointer;
}

.activities-item-type {
    border-radius: 5px;
    padding: 2px 4px;
    margin-right: 3px;
    font-size: 12px;
    background-color: #373737;
}

.activities-item-card {
    margin-top: 5px;
    display: flex;
    align-items: center;
}

.activities-item-number {
    width: 17px;
    height: 17px;
    margin-right: 6px;
    border-radius: 2px;
    text-align: center;
    border: 1px solid white;
}
