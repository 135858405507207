.activity-section-requirements {
    /*display: flex;*/
    margin: 5px 0 15px;
}

.activity-requirements {
    padding: 5px;
    font-size: 12px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: var(--background-card);
    width: fit-content;
}

.activity-section {
    margin: 5px 0 15px;
}

.activity-section-content {
    margin-top: 5px;
}

.activity-steps {
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    word-wrap: break-word;
    background-color: var(--background-card);
}
.activity-steps a {
    color: #07d;
}
.activity-steps p {
    margin: 0;
}
