.bottom-nav-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    /*padding-bottom: env(safe-area-inset-bottom);*/
    padding-bottom: 20px;
}
.bottom-nav-bar-button {
    flex: 1;
    font-size: 16px;
    color: gray;
    text-decoration: none;
    text-align: center;
    padding: 10px 0;
}
.bottom-nav-bar-button-active {
    color: white;
}

.setting-button-container {
    display: flex;
    justify-content: flex-end;
    /*position: fixed;*/
    /*bottom: 23px;*/
    /*left: 0;*/
}
.setting-button {
    color: white;
    background-color: #222222ba;
    border-radius: 3px;
    padding: 6px;
    /*padding-left: 0;*/
}
.setting-button:hover {
    cursor: pointer;
}

@media (min-width: 400px) {
    .bottom-nav-bar {
        padding-bottom: 0;
    }
    /*.setting-button-container {*/
    /*    bottom: 3px;*/
    /*    left: 5px;*/
    /*}*/
    .setting-button {
        padding-left: 6px;
    }
}
